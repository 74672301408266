import React from "react"
import { Row, Col } from "react-bootstrap"
import "../components/Jobs.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"

library.add(fab)

export default function SocialMediaIcons() {
  return (
    <Col className="social-icons">
      <Row>
        <a
          href="https://www.linkedin.com/company/iq-inc."
          target="_blank"
          title="LinkedIn"
        >
          <div class="fab">
            <div>
              <FontAwesomeIcon icon={["fab", "linkedin-in"]} />
            </div>
          </div>
        </a>
        <a
          href="https://www.instagram.com/iq_inc/"
          target="_blank"
          title="Instagram"
        >
          <div class="fab">
            <div>
              <FontAwesomeIcon icon={["fab", "instagram"]} />
            </div>
          </div>
        </a>
        <a
          href="https://www.facebook.com/4071IQInc/"
          target="_blank"
          title="Facebook"
        >
          <div class="fab">
            <div>
              <FontAwesomeIcon icon={["fab", "facebook-f"]} />
            </div>
          </div>
        </a>
        <a href="https://twitter.com/iq_inc" target="_blank" title="Twitter">
          <div class="fabEnd">
            <div>
              <FontAwesomeIcon icon={["fab", "twitter"]} />
            </div>
          </div>
        </a>
      </Row>
    </Col>
  )
}
