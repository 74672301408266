import React from "react"
import { Row } from "react-bootstrap"
import "../components/Jobs.scss"

export default function CTA() {
  return (
    <Row>
      <div class="cta"> Connect with us!</div>
    </Row>
  )
}
